<!--
 * @Author: 月魂
 * @Date: 2022-11-10 15:17:32
 * @LastEditTime: 2022-11-14 18:06:54
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\components\Navigation.vue
-->
<template>
  <div class="nav">
    <div class="top">
      <van-icon name="arrow-left" size="24" class="goBack" @click="goBack" />
      <div class="title">{{ title }}</div>
      <!-- 语言切换按钮 -->
      <div class="changeLang">
        <div v-if="lang === 'zh'" @click="changeLang('fr')" class="changeBtn">Fr</div>
        <div v-else @click="changeLang('zh')" class="changeBtn">中文</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { handleBack } from '@/utils'

Vue.use(Icon)

export default {
  name: 'Footer',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'fr',
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.lang = lang
      localStorage.setItem('lang', lang);
    },
    goBack() {
      handleBack()
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  width: 100%;
  line-height: 50px;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  padding: 0 16px 28px;
  position: relative;
  z-index: 1;
  .top {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    .goBack {
      line-height: 50px;
      cursor: pointer;
    }
    .title {
      color: #fff;
      font-size: 18px;
      line-height: 1.3;
      text-align: center;
      display: flex;
      align-items: center;
      word-break: break-word;
      box-sizing: border-box;
      padding: 0 16px;
    }
    .changeLang {
      min-width: 40px;
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .changeBtn {
        min-width: 40px;
        text-align: center;
        line-height: 30px;
        padding: 0 6px;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        border: 1px solid #fff;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
