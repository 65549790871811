/*
 * @Author: 月魂
 * @Date: 2022-09-22 13:57:57
 * @LastEditTime: 2022-09-27 16:31:22
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\utils\index.js
 */
import Router from '../router'

export function handleBack() {
  if(window.location.href.indexOf('isShare') !== -1) {
    Router.push('/');
  } else {
    Router.go(-1);
  }
}
